export function useTitle() {
  const general = useGeneralStore()

  function set(title: string) {
    general.setHeaderTitle(title)
    useHead({
      title,
    })
  }

  return {
    set,
  }
}
