export const useCookieBannerStore = defineStore('cookieBanner', () => {
  const isSelected = ref(true)
  const isManagingCookie = ref(false)

  const setIsSelected = (value: boolean) => {
    isSelected.value = value
  }
  const setManagingCookie = (value: boolean) => {
    isManagingCookie.value = value
  }

  return {
    isSelected,
    isManagingCookie,
    setIsSelected,
    setManagingCookie
  }
})
