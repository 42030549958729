import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccountCheckIcon, LazyAccountGroupIcon, LazyApplicationEditOutlineIcon, LazyArrowCircleLeftOutlineRoundedIcon, LazyArrowCircleRightOutlineIcon, LazyCalendarIcon, LazyCardAccountDetailsIcon, LazyCheckIcon, LazyCheckSmallIcon, LazyChevronDownIcon, LazyChevronRightIcon, LazyClockOutlineIcon, LazyCloseIcon, LazyCogIcon, LazyCookieIcon, LazyCreditCardIcon, LazyDollarSignIcon, LazyDotsHorizontalIcon, LazyDressIcon, LazyEyeIcon, LazyFacebookIcon, LazyFlagIcon, LazyGridIcon, LazyInstagramIcon, LazyKeyboardArrowDownIcon, LazyListIcon, LazyLoadingIcon, LazyLocationOnIcon, LazyLockOutlineIcon, LazyLoginIcon, LazyLogoutIcon, LazyMailRoundedIcon, LazyMenuIcon, LazyParkingIcon, LazyPencilOutlineIcon, LazyPersonIcon, LazyPhoneEnabledIcon, LazyPrinterIcon, LazyRetweetIcon, LazySendIcon, LazySettingIcon, LazySquareEditOutlineIcon, LazyTicketConfirmationIcon, LazyTicketIcon, LazyTrashOutlineIcon, LazyTwitterIcon, LazyUploadIcon, LazyUserIcon, LazyUsersIcon, LazyVoteIcon, LazyWindowIcon, LazyYoutubeIcon, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["AccountCheckIcon", LazyAccountCheckIcon],
["AccountGroupIcon", LazyAccountGroupIcon],
["ApplicationEditOutlineIcon", LazyApplicationEditOutlineIcon],
["ArrowCircleLeftOutlineRoundedIcon", LazyArrowCircleLeftOutlineRoundedIcon],
["ArrowCircleRightOutlineIcon", LazyArrowCircleRightOutlineIcon],
["CalendarIcon", LazyCalendarIcon],
["CardAccountDetailsIcon", LazyCardAccountDetailsIcon],
["CheckIcon", LazyCheckIcon],
["CheckSmallIcon", LazyCheckSmallIcon],
["ChevronDownIcon", LazyChevronDownIcon],
["ChevronRightIcon", LazyChevronRightIcon],
["ClockOutlineIcon", LazyClockOutlineIcon],
["CloseIcon", LazyCloseIcon],
["CogIcon", LazyCogIcon],
["CookieIcon", LazyCookieIcon],
["CreditCardIcon", LazyCreditCardIcon],
["DollarSignIcon", LazyDollarSignIcon],
["DotsHorizontalIcon", LazyDotsHorizontalIcon],
["DressIcon", LazyDressIcon],
["EyeIcon", LazyEyeIcon],
["FacebookIcon", LazyFacebookIcon],
["FlagIcon", LazyFlagIcon],
["GridIcon", LazyGridIcon],
["InstagramIcon", LazyInstagramIcon],
["KeyboardArrowDownIcon", LazyKeyboardArrowDownIcon],
["ListIcon", LazyListIcon],
["LoadingIcon", LazyLoadingIcon],
["LocationOnIcon", LazyLocationOnIcon],
["LockOutlineIcon", LazyLockOutlineIcon],
["LoginIcon", LazyLoginIcon],
["LogoutIcon", LazyLogoutIcon],
["MailRoundedIcon", LazyMailRoundedIcon],
["MenuIcon", LazyMenuIcon],
["ParkingIcon", LazyParkingIcon],
["PencilOutlineIcon", LazyPencilOutlineIcon],
["PersonIcon", LazyPersonIcon],
["PhoneEnabledIcon", LazyPhoneEnabledIcon],
["PrinterIcon", LazyPrinterIcon],
["RetweetIcon", LazyRetweetIcon],
["SendIcon", LazySendIcon],
["SettingIcon", LazySettingIcon],
["SquareEditOutlineIcon", LazySquareEditOutlineIcon],
["TicketConfirmationIcon", LazyTicketConfirmationIcon],
["TicketIcon", LazyTicketIcon],
["TrashOutlineIcon", LazyTrashOutlineIcon],
["TwitterIcon", LazyTwitterIcon],
["UploadIcon", LazyUploadIcon],
["UserIcon", LazyUserIcon],
["UsersIcon", LazyUsersIcon],
["VoteIcon", LazyVoteIcon],
["WindowIcon", LazyWindowIcon],
["YoutubeIcon", LazyYoutubeIcon],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
