import revive_payload_client_4sVQNw7RlN from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/.nuxt/sentry-client-config.mjs";
import plugin_client_LcKgStRyi6 from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_PSbK9A48ZQ from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import slideovers_X7whXyrIWR from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import api_GFfDXud5Cr from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/plugins/api.ts";
import error_handler_kEP5FliEXj from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/plugins/error-handler.ts";
import mobile_menu_JwEB5OFZLv from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/plugins/mobile-menu.ts";
import recaptcha_client_ElRG0N5AcO from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/plugins/recaptcha.client.ts";
import zendesk_client_8Sfi4oX528 from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/plugins/zendesk.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_client_LcKgStRyi6,
  plugin_PSbK9A48ZQ,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  api_GFfDXud5Cr,
  error_handler_kEP5FliEXj,
  mobile_menu_JwEB5OFZLv,
  recaptcha_client_ElRG0N5AcO,
  zendesk_client_8Sfi4oX528
]