export default defineNuxtPlugin(() => {
  // const { session } = useUserSession()
  const token = useCookie('asifa.auth')
  const refreshToken = useCookie('asifa.refresh')
  const userStore = useUserStore()
  const toast = useToast()

  const refreshTokens = async () => {
    if (refreshToken.value) {
      try {
        const res = await $fetch('/api/auth/refresh', {
          baseURL: `${useRuntimeConfig().public.apiBase}`,
          body: JSON.stringify({ refresh_token: refreshToken.value }),
        })
        userStore.setToken(res.data.access_token)
        await userStore.fetchUser()
      }
      catch (error) {
        userStore.setToken()
        userStore.setUser()
      }
    }
  }

  const $api = $fetch.create({
    baseURL: `${useRuntimeConfig().public.apiBase}`,
    onRequest({ options }) {
      if (token.value !== null) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token.value}`,
        }
      }
    },
    async onResponseError({ response }) {
      console.log('Error message', response.status)

      if (response.status === 401) {
        await refreshTokens()
        // Reload the page to get the new token
        window.location.reload()
      }
      else if (response._data.message === 'Voter not found') {
        console.log('Voter not found')
      }
      else {
        // toast.add({
        //   title: 'Error',
        //   description: response.statusText,
        //   color: 'red',
        // })
        // throw createError(response)
      }
    },
  })

  // Expose to useNuxtApp().$api
  return {
    provide: {
      api: $api,
    },
  }
})
