import * as Sentry from '@sentry/vue'
import { defineStore } from 'pinia'
import type { ILogin } from '~/types/response'

export const useUserStore = defineStore('user', () => {
  const user = ref()
  const isValid = ref(true)
  const loggedIn = computed(() => !!user.value)

  const token = useCookie('asifa.auth', {
    // Set to 1 day
    maxAge: 60 * 60 * 24,
    // httpOnly: !isProduction,
    // secure: isProduction,
    // sameSite: isProduction ? 'strict' : 'lax',
  })

  const refreshToken = useCookie('asifa.refresh', {
    maxAge: 60 * 60 * 24 * 7,
    // httpOnly: !isProduction,
    // secure: isProduction,
    // sameSite: isProduction ? 'strict' : 'lax',
  })

  const setToken = (data?: string) => (token.value = data)
  const setRefreshToken = (data?: string) => (refreshToken.value = data)
  const setUser = (data?: any) => (user.value = data)

  const fetchUser = async () => {
    if (token.value) {
      try {
        const res = await $fetch<any>('/api/auth/me', {
          baseURL: `${useRuntimeConfig().public.apiBase}`,
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        })
        setUser(res.data)
      }
      catch (error: any) {
        setToken()
        setRefreshToken()
        setUser()
        throw createError(error)
      }
    }
    else {
      setUser(null)
    }
  }

  const signIn = async (data: any) => {
    await $fetch<ILogin>('/api/auth/signin', {
      baseURL: `${useRuntimeConfig().public.apiBase}`,
      method: 'POST',
      body: data,
    })
      .then(async (res) => {
        setToken(res.data.access_token)
        setRefreshToken(res.data.refresh_token)
        await fetchUser()
        return true
      })
      .catch((error) => {
        if (error.status === 401)
          isValid.value = false
        setToken()
        setUser()
        return false
      })
  }

  const refreshTokens = async () => {
    try {
      const res = await $fetch<ILogin>('/api/auth/refresh-token', {
        baseURL: `${useRuntimeConfig().public.apiBase}`,
        method: 'POST',
        body: JSON.stringify({ refresh_token: refreshToken.value }),
      })
      setToken(res.data.access_token)
      setRefreshToken(res.data.refresh_token)
      await fetchUser()
    }
    catch (error) {
      setToken()
      setRefreshToken()
      setUser()
    }
  }

  const signOut = () => {
    setToken()
    setRefreshToken()
    setUser()
  }

  return {
    user,
    token,
    isValid,
    loggedIn,
    signIn,
    signOut,
    fetchUser,
    setUser,
    setToken,
    setRefreshToken,
    refreshTokens,
  }
})
