import { default as account_45lookuplkNXLep0HKMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/account-lookup.vue?macro=true";
import { default as addhm8AWJ3b7QMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/add.vue?macro=true";
import { default as deletedpYraKJa3XpMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/deleted.vue?macro=true";
import { default as _91id_934HkSRTyc1uMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/edit/[id].vue?macro=true";
import { default as indexUl5JCr1QevMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/index.vue?macro=true";
import { default as _91id_93QcCRok1MhGMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/view/[id].vue?macro=true";
import { default as companies15oN3twscQMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies.vue?macro=true";
import { default as _91id_93GuSOJYq22tMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/company-suggestions/confirm/[id].vue?macro=true";
import { default as indexQxGJXoOSmlMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/company-suggestions/index.vue?macro=true";
import { default as indexnU3AvFF0BTMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/configs/index.vue?macro=true";
import { default as dashboard84q1hnz6OTMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/dashboard.vue?macro=true";
import { default as addqlHwg0gxyDMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/add.vue?macro=true";
import { default as _91id_93RE2ZypsqDxMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/appeal/[id].vue?macro=true";
import { default as _91id_93d2duIESRD6Meta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/edit/[id].vue?macro=true";
import { default as indextR2tQkUP0RMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/index.vue?macro=true";
import { default as _91id_93QPRx38mziTMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/view/[id].vue?macro=true";
import { default as indexiTdjScbVv2Meta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/judges/index.vue?macro=true";
import { default as indexlgtJW0Vf2yMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/card-scan/index.vue?macro=true";
import { default as indexeVxSCXLF77Meta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/email/index.vue?macro=true";
import { default as indexbBOVF4jEInMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/index.vue?macro=true";
import { default as indexjri81Lzn37Meta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/mailchimp/index.vue?macro=true";
import { default as index1ZZMCiVSUhMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/payment/index.vue?macro=true";
import { default as logsjKF3kZhfidMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs.vue?macro=true";
import { default as addkLsFKUPfmnMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/add.vue?macro=true";
import { default as indee_45active_45membersXeXco5k4ymMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/indee-active-members.vue?macro=true";
import { default as index20Vs8tjBdcMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/index.vue?macro=true";
import { default as indexP8XBMmQD1TMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/merge/[id]/index.vue?macro=true";
import { default as _91withId_93Caf0ujiErcMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/merge/[id]/with/[withId].vue?macro=true";
import { default as index61E643WQYiMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/merge/index.vue?macro=true";
import { default as _91id_93my0RA4vo6nMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/merge/resolve/[id].vue?macro=true";
import { default as resend_45welcome_45emailIQ9nyDNC3MMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/resend-welcome-email.vue?macro=true";
import { default as screener_45listsFNi6X0vFEqMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/screener-lists.vue?macro=true";
import { default as indexnmEn6WHX1wMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/student-applications/index.vue?macro=true";
import { default as _91id_93D8QxsGtIJbMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/student-applications/view/[id].vue?macro=true";
import { default as membersKL2joBlQoSMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members.vue?macro=true";
import { default as statisticsSTnxpJLHHnMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/memberships/statistics.vue?macro=true";
import { default as _91id_93NzgRI2A4E4Meta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/memberships/update/[id].vue?macro=true";
import { default as index1psDQhJSrjMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/payments/index.vue?macro=true";
import { default as addeAPySBFDvZMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code/add.vue?macro=true";
import { default as _91id_93KkAvIJfhfVMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code/edit/[id].vue?macro=true";
import { default as indexnXM3eCWmw6Meta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code/index.vue?macro=true";
import { default as _91id_93AEBVSyoR1rMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code/view/[id].vue?macro=true";
import { default as promo_45code3DyqWTRyBcMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code.vue?macro=true";
import { default as screeningsRzNnnwmbNzMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/screenings.vue?macro=true";
import { default as indextCOJxHUEMZMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/tickets/index.vue?macro=true";
import { default as inviteYC5HsAMeJxMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/tickets/invite.vue?macro=true";
import { default as _91code_93gJTge5YqZ7Meta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/tickets/receipt/[code].vue?macro=true";
import { default as ticketsHyoGuOVMnRMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/tickets.vue?macro=true";
import { default as indexG1s3zWhbzMMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/voter-qualifications/index.vue?macro=true";
import { default as dashboardBS1FYItivgMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/dashboard.vue?macro=true";
import { default as index0Lb8RGWnjGMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/index.vue?macro=true";
import { default as _91invoiceId_93oQwUA3rtw9Meta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/invoice/[invoiceId].vue?macro=true";
import { default as add0abPe8vV5mMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/add.vue?macro=true";
import { default as multiple_45addKadHRtTdDTMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/multiple-add.vue?macro=true";
import { default as indexqzPpemRPmkMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/resolve-similar/index.vue?macro=true";
import { default as companiesogNAaMH8grMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies.vue?macro=true";
import { default as indextdofqiYOukMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/events/screening/[id]/index.vue?macro=true";
import { default as forgot_45passwordwOe2pFKHEtMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/forgot-password.vue?macro=true";
import { default as indexblRPF22iZ2Meta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/index.vue?macro=true";
import { default as invoicesi5FjZpckM8Meta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/invoices.vue?macro=true";
import { default as applyNxBZK1trCdMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/judges/apply.vue?macro=true";
import { default as _91_46_46_46id_93jtfadeliopMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/addresses/[...id].vue?macro=true";
import { default as _91id_93AmfMOQxOXbMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/edit/[id].vue?macro=true";
import { default as index3mB5dZMHnrMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/membership/index.vue?macro=true";
import { default as index7n8icLEunQMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/signup/index.vue?macro=true";
import { default as _91_46_46_46id_93eDPeZgYalbMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/view/[...id].vue?macro=true";
import { default as members2NsTdKNPJiMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members.vue?macro=true";
import { default as indexO24YnyQ9kvMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/card/index.vue?macro=true";
import { default as _91_46_46_46id_93W51RHMvX7TMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/card/print/[...id].vue?macro=true";
import { default as _91id_93ck1yWIuBr7Meta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/invoice/[id].vue?macro=true";
import { default as indexQFc0gJXAExMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/my-membership/index.vue?macro=true";
import { default as _91id_93cLliuanCCJMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/update/[id].vue?macro=true";
import { default as _91invoiceId_93r3vMV4nqEDMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/update/invoice/[invoiceId].vue?macro=true";
import { default as password_45resetm0gG9GOphtMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/password-reset.vue?macro=true";
import { default as indexvMyYVRyOpuMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/payments/index.vue?macro=true";
import { default as _91id_930cXLzvLkqiMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/payments/invoice/[id].vue?macro=true";
import { default as _91id_93cIrTNwOpdgMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/payments/receipt/[id].vue?macro=true";
import { default as _91id_938fDXvpNjadMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/payments/view/[id].vue?macro=true";
import { default as callback6VsQDjDrFDMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/qb/callback.vue?macro=true";
import { default as logoutrKlFscNLgEMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/redirect/logout.vue?macro=true";
import { default as index71HRBcWdUcMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/index.vue?macro=true";
import { default as _91token_93NAu9AevbeOMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/invite/[token].vue?macro=true";
import { default as _91token_93rq4RpOx4BQMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/invite/payment/[token].vue?macro=true";
import { default as _91code_93tpPJpfhrvIMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/payment/[code].vue?macro=true";
import { default as indexwe20I80wU8Meta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/payment/index.vue?macro=true";
import { default as _91code_93NV0qdiSqTcMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/receipt/[code].vue?macro=true";
import { default as _91code_93RLMDXlB6YnMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/view/[code].vue?macro=true";
import { default as ticketsb3hpwWbp2YMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets.vue?macro=true";
import { default as voteiby6O2OLkpMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/vote.vue?macro=true";
import { default as component_45stubb1EUnczN3pMeta } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubb1EUnczN3p } from "/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account-lookup",
    path: "/account-lookup",
    meta: account_45lookuplkNXLep0HKMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/account-lookup.vue")
  },
  {
    name: companies15oN3twscQMeta?.name,
    path: "/admin/companies",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies.vue"),
    children: [
  {
    name: "admin-companies-add",
    path: "add",
    meta: addhm8AWJ3b7QMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/add.vue")
  },
  {
    name: "admin-companies-deleted",
    path: "deleted",
    meta: deletedpYraKJa3XpMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/deleted.vue")
  },
  {
    name: "admin-companies-edit-id",
    path: "edit/:id()",
    meta: _91id_934HkSRTyc1uMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/edit/[id].vue")
  },
  {
    name: "admin-companies",
    path: "",
    meta: indexUl5JCr1QevMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/index.vue")
  },
  {
    name: "admin-companies-view-id",
    path: "view/:id()",
    meta: _91id_93QcCRok1MhGMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/companies/view/[id].vue")
  }
]
  },
  {
    name: "admin-company-suggestions-confirm-id",
    path: "/admin/company-suggestions/confirm/:id()",
    meta: _91id_93GuSOJYq22tMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/company-suggestions/confirm/[id].vue")
  },
  {
    name: "admin-company-suggestions",
    path: "/admin/company-suggestions",
    meta: indexQxGJXoOSmlMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/company-suggestions/index.vue")
  },
  {
    name: "admin-configs",
    path: "/admin/configs",
    meta: indexnU3AvFF0BTMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/configs/index.vue")
  },
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    meta: dashboard84q1hnz6OTMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/dashboard.vue")
  },
  {
    name: "admin-events-add",
    path: "/admin/events/add",
    meta: addqlHwg0gxyDMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/add.vue")
  },
  {
    name: "admin-events-appeal-id",
    path: "/admin/events/appeal/:id()",
    meta: _91id_93RE2ZypsqDxMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/appeal/[id].vue")
  },
  {
    name: "admin-events-edit-id",
    path: "/admin/events/edit/:id()",
    meta: _91id_93d2duIESRD6Meta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/edit/[id].vue")
  },
  {
    name: "admin-events",
    path: "/admin/events",
    meta: indextR2tQkUP0RMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/index.vue")
  },
  {
    name: "admin-events-view-id",
    path: "/admin/events/view/:id()",
    meta: _91id_93QPRx38mziTMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/events/view/[id].vue")
  },
  {
    name: "admin-judges",
    path: "/admin/judges",
    meta: indexiTdjScbVv2Meta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/judges/index.vue")
  },
  {
    name: logsjKF3kZhfidMeta?.name,
    path: "/admin/logs",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs.vue"),
    children: [
  {
    name: "admin-logs-card-scan",
    path: "card-scan",
    meta: indexlgtJW0Vf2yMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/card-scan/index.vue")
  },
  {
    name: "admin-logs-email",
    path: "email",
    meta: indexeVxSCXLF77Meta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/email/index.vue")
  },
  {
    name: "admin-logs",
    path: "",
    meta: indexbBOVF4jEInMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/index.vue")
  },
  {
    name: "admin-logs-mailchimp",
    path: "mailchimp",
    meta: indexjri81Lzn37Meta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/mailchimp/index.vue")
  },
  {
    name: "admin-logs-payment",
    path: "payment",
    meta: index1ZZMCiVSUhMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/logs/payment/index.vue")
  }
]
  },
  {
    name: membersKL2joBlQoSMeta?.name,
    path: "/admin/members",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members.vue"),
    children: [
  {
    name: "admin-members-add",
    path: "add",
    meta: addkLsFKUPfmnMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/add.vue")
  },
  {
    name: "admin-members-indee-active-members",
    path: "indee-active-members",
    meta: indee_45active_45membersXeXco5k4ymMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/indee-active-members.vue")
  },
  {
    name: "admin-members",
    path: "",
    meta: index20Vs8tjBdcMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/index.vue")
  },
  {
    name: "admin-members-merge-id",
    path: "merge/:id()",
    meta: indexP8XBMmQD1TMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/merge/[id]/index.vue")
  },
  {
    name: "admin-members-merge-id-with-withId",
    path: "merge/:id()/with/:withId()",
    meta: _91withId_93Caf0ujiErcMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/merge/[id]/with/[withId].vue")
  },
  {
    name: "admin-members-merge",
    path: "merge",
    meta: index61E643WQYiMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/merge/index.vue")
  },
  {
    name: "admin-members-merge-resolve-id",
    path: "merge/resolve/:id()",
    meta: _91id_93my0RA4vo6nMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/merge/resolve/[id].vue")
  },
  {
    name: "admin-members-resend-welcome-email",
    path: "resend-welcome-email",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/resend-welcome-email.vue")
  },
  {
    name: "admin-members-screener-lists",
    path: "screener-lists",
    meta: screener_45listsFNi6X0vFEqMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/screener-lists.vue")
  },
  {
    name: "admin-members-student-applications",
    path: "student-applications",
    meta: indexnmEn6WHX1wMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/student-applications/index.vue")
  },
  {
    name: "admin-members-student-applications-view-id",
    path: "student-applications/view/:id()",
    meta: _91id_93D8QxsGtIJbMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/members/student-applications/view/[id].vue")
  }
]
  },
  {
    name: "admin-memberships-statistics",
    path: "/admin/memberships/statistics",
    meta: statisticsSTnxpJLHHnMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/memberships/statistics.vue")
  },
  {
    name: "admin-memberships-update-id",
    path: "/admin/memberships/update/:id()",
    meta: _91id_93NzgRI2A4E4Meta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/memberships/update/[id].vue")
  },
  {
    name: "admin-payments",
    path: "/admin/payments",
    meta: index1psDQhJSrjMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/payments/index.vue")
  },
  {
    name: promo_45code3DyqWTRyBcMeta?.name,
    path: "/admin/promo-code",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code.vue"),
    children: [
  {
    name: "admin-promo-code-add",
    path: "add",
    meta: addeAPySBFDvZMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code/add.vue")
  },
  {
    name: "admin-promo-code-edit-id",
    path: "edit/:id()",
    meta: _91id_93KkAvIJfhfVMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code/edit/[id].vue")
  },
  {
    name: "admin-promo-code",
    path: "",
    meta: indexnXM3eCWmw6Meta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code/index.vue")
  },
  {
    name: "admin-promo-code-view-id",
    path: "view/:id()",
    meta: _91id_93AEBVSyoR1rMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/promo-code/view/[id].vue")
  }
]
  },
  {
    name: "admin-screenings",
    path: "/admin/screenings",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/screenings.vue")
  },
  {
    name: ticketsHyoGuOVMnRMeta?.name,
    path: "/admin/tickets",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/tickets.vue"),
    children: [
  {
    name: "admin-tickets",
    path: "",
    meta: indextCOJxHUEMZMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/tickets/index.vue")
  },
  {
    name: "admin-tickets-invite",
    path: "invite",
    meta: inviteYC5HsAMeJxMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/tickets/invite.vue")
  },
  {
    name: "admin-tickets-receipt-code",
    path: "receipt/:code()",
    meta: _91code_93gJTge5YqZ7Meta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/tickets/receipt/[code].vue")
  }
]
  },
  {
    name: "admin-voter-qualifications",
    path: "/admin/voter-qualifications",
    meta: indexG1s3zWhbzMMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/admin/voter-qualifications/index.vue")
  },
  {
    name: "companies",
    path: "/companies",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies.vue"),
    children: [
  {
    name: "companies-manager-dashboard",
    path: "manager/dashboard",
    meta: dashboardBS1FYItivgMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/dashboard.vue")
  },
  {
    name: "companies-manager-view-id",
    path: "manager/view/:id()",
    meta: index0Lb8RGWnjGMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/index.vue")
  },
  {
    name: "companies-manager-view-id-invoice-invoiceId",
    path: "manager/view/:id()/invoice/:invoiceId()",
    meta: _91invoiceId_93oQwUA3rtw9Meta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/invoice/[invoiceId].vue")
  },
  {
    name: "companies-manager-view-id-member-add",
    path: "manager/view/:id()/member/add",
    meta: add0abPe8vV5mMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/add.vue")
  },
  {
    name: "companies-manager-view-id-member-multiple-add",
    path: "manager/view/:id()/member/multiple-add",
    meta: multiple_45addKadHRtTdDTMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/multiple-add.vue")
  },
  {
    name: "companies-manager-view-id-member-resolve-similar",
    path: "manager/view/:id()/member/resolve-similar",
    meta: indexqzPpemRPmkMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/resolve-similar/index.vue")
  }
]
  },
  {
    name: "events-screening-id",
    path: "/events/screening/:id()",
    meta: indextdofqiYOukMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/events/screening/[id]/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordwOe2pFKHEtMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexblRPF22iZ2Meta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/index.vue")
  },
  {
    name: "invoices",
    path: "/invoices",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/invoices.vue")
  },
  {
    name: "judges-apply",
    path: "/judges/apply",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/judges/apply.vue")
  },
  {
    name: "members",
    path: "/members",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members.vue"),
    children: [
  {
    name: "members-addresses-id",
    path: "addresses/:id(.*)*",
    meta: _91_46_46_46id_93jtfadeliopMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/addresses/[...id].vue")
  },
  {
    name: "members-edit-id",
    path: "edit/:id()",
    meta: _91id_93AmfMOQxOXbMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/edit/[id].vue")
  },
  {
    name: "members-membership",
    path: "membership",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/membership/index.vue")
  },
  {
    name: "members-signup",
    path: "signup",
    meta: index7n8icLEunQMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/signup/index.vue")
  },
  {
    name: "members-view-id",
    path: "view/:id(.*)*",
    meta: _91_46_46_46id_93eDPeZgYalbMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/members/view/[...id].vue")
  }
]
  },
  {
    name: "memberships-card",
    path: "/memberships/card",
    meta: indexO24YnyQ9kvMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/card/index.vue")
  },
  {
    name: "memberships-card-print-id",
    path: "/memberships/card/print/:id(.*)*",
    meta: _91_46_46_46id_93W51RHMvX7TMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/card/print/[...id].vue")
  },
  {
    name: "memberships-invoice-id",
    path: "/memberships/invoice/:id()",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/invoice/[id].vue")
  },
  {
    name: "memberships-my-membership",
    path: "/memberships/my-membership",
    meta: indexQFc0gJXAExMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/my-membership/index.vue")
  },
  {
    name: "memberships-update-id",
    path: "/memberships/update/:id()",
    meta: _91id_93cLliuanCCJMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/update/[id].vue")
  },
  {
    name: "memberships-update-invoice-invoiceId",
    path: "/memberships/update/invoice/:invoiceId()",
    meta: _91invoiceId_93r3vMV4nqEDMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/memberships/update/invoice/[invoiceId].vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: password_45resetm0gG9GOphtMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/password-reset.vue")
  },
  {
    name: "payments",
    path: "/payments",
    meta: indexvMyYVRyOpuMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/payments/index.vue")
  },
  {
    name: "payments-invoice-id",
    path: "/payments/invoice/:id()",
    meta: _91id_930cXLzvLkqiMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/payments/invoice/[id].vue")
  },
  {
    name: "payments-receipt-id",
    path: "/payments/receipt/:id()",
    meta: _91id_93cIrTNwOpdgMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/payments/receipt/[id].vue")
  },
  {
    name: "payments-view-id",
    path: "/payments/view/:id()",
    meta: _91id_938fDXvpNjadMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/payments/view/[id].vue")
  },
  {
    name: "qb-callback",
    path: "/qb/callback",
    meta: callback6VsQDjDrFDMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/qb/callback.vue")
  },
  {
    name: "redirect-logout",
    path: "/redirect/logout",
    meta: logoutrKlFscNLgEMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/redirect/logout.vue")
  },
  {
    name: ticketsb3hpwWbp2YMeta?.name,
    path: "/tickets",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets.vue"),
    children: [
  {
    name: "tickets",
    path: "",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/index.vue")
  },
  {
    name: "tickets-invite-token",
    path: "invite/:token()",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/invite/[token].vue")
  },
  {
    name: "tickets-invite-payment-token",
    path: "invite/payment/:token()",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/invite/payment/[token].vue")
  },
  {
    name: "tickets-payment-code",
    path: "payment/:code()",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/payment/[code].vue")
  },
  {
    name: "tickets-payment",
    path: "payment",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/payment/index.vue")
  },
  {
    name: "tickets-receipt-code",
    path: "receipt/:code()",
    meta: _91code_93NV0qdiSqTcMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/receipt/[code].vue")
  },
  {
    name: "tickets-view-code",
    path: "view/:code()",
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/tickets/view/[code].vue")
  }
]
  },
  {
    name: "vote",
    path: "/vote",
    meta: voteiby6O2OLkpMeta || {},
    component: () => import("/tmp/codebuild/output/src2310/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/f058762b-1820-4ccd-b63a-b0d8128357ce/clearview/asifa-members-fe/pages/vote.vue")
  },
  {
    name: component_45stubb1EUnczN3pMeta?.name,
    path: "/judge-application",
    component: component_45stubb1EUnczN3p
  },
  {
    name: component_45stubb1EUnczN3pMeta?.name,
    path: "/profile",
    component: component_45stubb1EUnczN3p
  },
  {
    name: component_45stubb1EUnczN3pMeta?.name,
    path: "/login",
    component: component_45stubb1EUnczN3p
  },
  {
    name: component_45stubb1EUnczN3pMeta?.name,
    path: "/join",
    component: component_45stubb1EUnczN3p
  },
  {
    name: component_45stubb1EUnczN3pMeta?.name,
    path: "/memberships/my_membership",
    component: component_45stubb1EUnczN3p
  }
]