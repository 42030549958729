<template>
  <label for="toogleA" class="flex items-center cursor-pointer">
    <div class="relative">
      <input
        id="toogleA"
        type="checkbox"
        class="sr-only"
        :checked="checked"
        @change="handleChange($event)"
      />

      <div class="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>

      <div
        class="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"
      ></div>
    </div>
  </label>
</template>

<script setup lang="ts">
defineProps<{
  checked?: boolean
}>()

const emit = defineEmits<{
  (e: 'change', value: any): void
}>()

// @TODO: Fix this any type
const handleChange = (event: any) => {
  emit('change', event.target.checked)
}
</script>
<style scoped>
input:checked ~ .dot {
  @apply bg-yellow-asifa translate-x-full;
}
</style>
