import * as Sentry from '@sentry/nuxt'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    const userStore = useUserStore()
    // Ensure error is an Error object with a message
    const normalizedError = normalizeError(error)

    if (shouldIgnoreError(normalizedError)) {
      console.warn('Ignored error:', normalizedError)
      return
    }

    Sentry.withScope((scope) => {
      if (userStore?.user) {
        scope.setUser({
          id: userStore.user.id,
          email: userStore.user.email,
        })
      }

      scope.setExtra('componentName', instance?.$options?.name || 'Unknown')
      scope.setExtra('info', info)

      Sentry.captureException(normalizedError)
    })
  }
})

function normalizeError(error: unknown): Error {
  if (error instanceof Error) {
    if (!error.message) {
      error.message = 'No error message provided'
    }
    return error
  }
  if (typeof error === 'string') {
    return new Error(error)
  }
  return new Error(JSON.stringify(error))
}

function shouldIgnoreError(error: Error): boolean {
  const message = error.message.toLowerCase()
  if (message.includes('cannot read properties of null (reading \'startswith\')')) {
    return true
  }
  return false
}
