export default defineAppConfig({
  ui: {
    primary: 'yellow',
    formGroup: {
      label: {
        base: 'block text-xs uppercase text-gray-400',
      },
      help: 'text-xs text-gray-400',

    },
    alert: {
      rounded: 'rounded-none',
    },
    input: {
      rounded: 'rounded-none',
      padding: {
        sm: 'py-3 px-2',
      },
      default: {
        size: 'sm',
      },
    },
    textarea: {
      rounded: 'rounded-none',
      padding: {
        sm: 'py-3 px-2',
      },
      default: {
        size: 'sm',
      },
    },
    select: {
      rounded: 'rounded-none',
      padding: {
        sm: 'py-3 px-2',
      },
      default: {
        size: 'sm',
      },
    },
    button: {
      rounded: 'rounded-none',
      font: 'font-semibold font-montserrat',
      padding: {
        md: 'py-3 px-6',
      },
      default: {
        size: 'md',
      },
      icon: {
        size: {
          md: 'h-3 w-3',
        },
      },
    },
    buttonGroup: {
      wrapper: {
        horizontal: 'w-full',
      },
      rounded: 'rounded-none',
      shadow: 'shadow-none',
    },
    table: {
      wrapper: 'w-full border border-gray-200',
      base: 'table-auto',
      tr: {
        base: 'hover:bg-gray-100',
      },
      th: {
        size: 'text-xs',
      },
      td: {
        size: 'text-xs',
      },
      default: {
        sortAscIcon: 'i-tabler-caret-up-filled',
        sortDescIcon: 'i-tabler-caret-down-filled',
        sortButton: {
          icon: 'i-tabler-caret-up-down-filled',
          class: 'text-xs flex gap-x-1',
        },
      },
    },
    pagination: {
      wrapper: 'justify-end py-4',
      rounded: 'first:rounded-s-none last:rounded-e-none',
    },
    breadcrumb: {
      wrapper: 'pb-6',
    },
    dropdown: {
      width: 'w-62',
      rounded: 'rounded-none',
      item: {
        size: 'text-xs',
      },
    },
    badge: {
      base: 'uppercase',
      font: 'font-semibold',
      rounded: 'rounded-none',
      size: {
        sm: 'text-[10px] px-1.5 py-0.5',
      },
    },
    card: {
      base: 'border border-1 border-gray-200',
      shadow: 'shadow-none',
      rounded: 'rounded-none',
      divide: 'divide-none',
      header: {
        // padding: 'px-2 py-2 sm:px-6 sm:py-2',
      },
    },
    modal: {
      rounded: 'rounded-none',
    },
  },
})
