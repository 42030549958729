import { defineStore } from 'pinia'
import type { IUser } from '@/types/member'
import type { IMembershipType } from '@/types/membership'

export const useGeneralStore = defineStore('general', () => {
  const state = reactive({
    isModalOpen: false,
    isMobileSidebarOpen: false,
    isLoggedAsMember: false,
    headerTitle: '',
    loginAsData: {} as IUser,
    selectedTicketPrice: 0,
    ticketPriceDeadline: '',
    openedModalId: '',
    membershipTypesOptions: [] as IMembershipType[],
    paymentState: 'invoices',
  })

  const setModalOpen = (value: boolean, id = '') => {
    state.isModalOpen = value
    state.openedModalId = id
  }

  const setHeaderTitle = (title: string) => {
    state.headerTitle = title
  }

  const setLoginAsData = (data: IUser) => {
    state.loginAsData = data
  }

  const setLoggedAsMember = (value: boolean) => {
    state.isLoggedAsMember = value
  }

  const setSelectedTicketPrice = (value: number) => {
    state.selectedTicketPrice = value
  }

  const setTicketPriceDeadline = (value: string | undefined) => {
    state.ticketPriceDeadline = value || ''
  }

  const setMembershipTypesOptions = (value: IMembershipType[]) => {
    state.membershipTypesOptions = value
  }

  const setMobileSidebarOpen = (value: boolean) => {
    state.isMobileSidebarOpen = value
  }

  const setPaymentState = (value: string) => {
    state.paymentState = value
  }

  return {
    ...toRefs(state),
    setModalOpen,
    setHeaderTitle,
    setLoginAsData,
    setLoggedAsMember,
    setSelectedTicketPrice,
    setTicketPriceDeadline,
    setMembershipTypesOptions,
    setMobileSidebarOpen,
    setPaymentState,
  }
}, {
  persist: {
    storage: sessionStorage,
    pick: ['isLoggedAsMember', 'loginAsData'],
  },
})
